import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
    margin: .2rem .5rem .2rem 0;
    text-decoration: none;
    transition: all 0.5s;
    color: #ffffff;

    :hover, ion-icon:hover{
        color: #00f5d4;
    }
`

const Linkage = ({target, nombre, to}) =>{
    if(target){
        return <StyledLink to={to} target={target} rel="noopener noreferrer">{nombre}</StyledLink>
    }else{
        return <StyledLink to={to}>{nombre}</StyledLink>
    }
    
}

export default Linkage;