import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Logo from './Logo';
import Title from './Title';
import styled from 'styled-components';
import { menuOptions } from '../assets/js/data';
import Linkage from './Linkage';
import MSImg from "../assets/images/logo_mercadoshops.svg";


const ContainerNav = styled.div`
  background: #00bbf9;
  margin-bottom: 1rem;

  .navbar-expand-md{
      padding: 0;
  }

  .mb-3{
    margin-bottom: 0 !important;
  }

    .container-fluid{
        padding: 10px;
        max-width: 1440px;
    }

    .nav-link{
      display: flex;
    }

    .nav-link a{
      font-size: 18px;
      margin: auto;

      :after {
        content: "";
        display: block;
        margin: auto;
        height: 2px;
        width: 0px;
        transition: all .5s;
      }

      :hover:after {
        width: 100%;
        background: #00f5d4;
      }

      :hover{
        color: #00f5d4;
      }
    }

    @media screen and (max-width: 550px) {
        h1{
          display: none;
        }
    }
`

const Img = styled.img`
  width: 110px;
  transition: all .3s;
  padding: 5px;

  :hover{
    background: #00f5d4;
    border-radius: 15px;
  }
`

function HeaderNav() {
    const expand = "md";
  return (
    <ContainerNav>
        <Navbar expand={expand} className="mb-3">
          <Container fluid>
            <Logo/>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    Menú
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                    {menuOptions.map( (option, i) => {
                        const {nombre, ruta} = option;

                        if(!option.target){
                            return <Nav.Link key={i}><Linkage nombre={nombre} to={ruta}/></Nav.Link>  
                        }else{
                            return <Nav.Link key={i} href={ruta} target="_blank" rel="noopener noreferrer"> <Img src={MSImg} alt='Imagen tienda'/> </Nav.Link>
                        }
                        
                    })}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
    </ContainerNav>
  );
}

export default HeaderNav;