import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const StyledMenu = styled.ul`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-around;
    flex-wrap: wrap;
`

const Option = styled.li`
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-bottom: .5rem;

    a{  
        text-decoration: none;
        color: #00bbf9;
        border-bottom: 2px solid rgba(0, 0, 0, 0);
        transition: all .3s;
        padding: 0 1rem;

        :hover{
            color: #00f5d4;
            border-bottom: 2px solid #00f5d4;
            transition: all .3s;
        }
    } 
`

const SelectedOption = styled(Option)`
    background-color: #00bbf9;
    height: 30px;
    border-radius: 18px;

    a{
        color: white;
    }

    :hover{
        a{
            border-bottom: 2px solid rgba(0, 0, 0, 0);
        }
    }
`

const Menu = ({options}) => {
    const [selectedOption, setSelectedOption] = useState("")
    const location = useLocation();
    const ruta = location.pathname.split('/').pop();

    useEffect(() => {
        setSelectedOption(ruta);
    }, [ruta]);

    
    return <StyledMenu>
        {options.map((option, i) => {
            if (selectedOption === option[1]){
                return <SelectedOption key={i}><Link to={option[1]}>{option[0]}</Link></SelectedOption>
            } else {
                return <Option key={i}><Link to={option[1]}>{option[0]}</Link></Option>
            }
        })}
    </StyledMenu>
}

export default Menu;