import Local from "../images/local.png";
import Pcs from "../images/pcs.png";
import Impresoras from "../images/banner/impresoras.png";
import ServiceImg from "../images/banner/servicio.png";
import T250 from "../images/T250.png";
import SoportePcImg from "../images/soportetecpc.jpg";
import SoporteImpImg from "../images/soportetecimp.jpg";
import SoporteUpImg from "../images/soporteuptate.jpg";

export const menuOptions = [
    {
        nombre: "Inicio",
        ruta: "/"
    },
    {
        nombre: "Tienda",
        ruta: "https://ryccucuta.mercadoshops.com.co/",
        target: "_blank"
    },
    {
        nombre: "Servicios",
        ruta: "/servicios/soporte",
    },
    /* {
        nombre: "Tutoriales",
        ruta: "/tutoriales"
    }, */
    {
        nombre: "Contáctanos",
        ruta: "/contacto"
    }
];

export const bannerInfo = [
    {
        title: "SOMOS R&C COMPUTADORES CUCUTA",
        texto: "Tienda de computación con más de 10 años de experiencia en el sector.",
        img: Local
    },
    {
        title: "EQUIPOS CORPORATIVOS DE SEGUNDA MANO",
        texto: "¿Necesitas un equipo con buenas especificaciones y a un precio asequible?",
        img: Pcs
    },
    {
        title: "IMPRESORAS PARA TU CASA, NEGOCIO U OFICINA",
        texto: "¿No sabes qué impresora necesitas? Déjanos asesorarte y ayudarte a elegir la que más se adapte a tus necesidades.",
        img: Impresoras
    },
    {
        title: "SERVICIO TECNICO ESPECIALIZADO",
        texto: "Tus equipos siempre estarán en manos de expertos.",
        img: ServiceImg
    }
];

export const cardsInfo =[
    {
        iconName: "construct-outline",
        title: "SOPORTE TECNICO",
        description: "Contamos con un soporte técnico de computadores e impresoras confiable, con más de 10 años de experiencia en el mercado.",
        route: "soporte"
    },
    {
        iconName: "desktop-outline",
        title: "DESARROLLO DE SOFTWARE",
        description: "Nuestro equipo de desarrollo transformará tus ideas en realidad e impulsara tu negocio, Desarrollo de software a tu medida.",
        route: "software"
    },
    {
        iconName: "code-slash-outline",
        title: "DESARROLLO WEB",
        description: "Desarrollamos tus sitios web dinámicos y estáticos, además de brindarte asesoría técnica para que puedas tener el sitio web perfecto para tu negocio.",
        route: "web"
    },
    {
        iconName: "headset-outline",
        title: "ACCESORIOS",
        description: "Potencia tu computadora al máximo y mejora tu experiencia informática, accesorios imprescindibles de calidad que te ayudaran en tu día a día.",
        route: "accesorios"
    },
    {
        iconName: "hardware-chip-outline",
        title: "REPUESTOS",
        description: "Recupera el rendimiento óptimo de tu equipo, pregunta por los repuestos de impresoras y computadoras que tenemos disponibles para ti.",
        route: "repuestos"
    },
    {
        iconName: "water-outline",
        title: "SUMINISTROS",
        description: "¿Necesitas tinta para impresora?, con nosotros puedes encontrar las mejores marcas de tinta para todo tipo de impresoras.",
        route: "suministros"
    }
];

export const techSupportData = [
    {
        tipe: "banner",
        title: "Programación para Plotters HP T250 y T650",
        subtitle: "Optimiza tu Plotter HP: Programación para Trabajar sin Cartuchos (chipless)",
        text: "En R&C Computadores Cúcuta, ofrecemos un servicio especializado de programación para plotters HP T250 y T650, permitiéndote liberarte de la dependencia de cartuchos costosos. Nuestros expertos ajustarán tu plotter para que funcione eficientemente sin la necesidad de cartuchos, ahorrándote dinero y eliminando la preocupación de reemplazos frecuentes. Descubre una forma más económica y sostenible de imprimir con nuestros servicios de programación.",
        img: T250,
    },
    {
        tipe: "banner",
        title: "Mantenimiento y Reparación de Equipos de Cómputo",
        subtitle: "Mantenimiento y Reparación: Mantén tus Equipos en Óptimo Rendimiento",
        text: "Entendemos la importancia de que tus equipos de cómputo operen sin problemas. Nuestro servicio de mantenimiento y reparación garantiza que tus sistemas estén siempre en óptimas condiciones. Desde diagnósticos rápidos hasta soluciones efectivas, nuestro equipo está listo para mantener tus estaciones de trabajo funcionando sin contratiempos.",
        img: SoportePcImg,
    },
    {
        tipe: "banner",
        title: "Mantenimiento y Reparación de Equipos de Impresion",
        subtitle: "Precisión y Eficiencia: Mantenimiento de Impresoras de Sistema de Tinta",
        text: "Las impresoras de sistema de tinta son esenciales para una impresión de alta calidad. Ofrecemos servicios especializados de mantenimiento y reparación para garantizar que tus impresoras funcionen sin problemas. Nuestro equipo técnico capacitado se encarga de la limpieza, ajustes y reparaciones necesarias para mantener la precisión y eficiencia de tus impresiones. Confía en nosotros para maximizar la vida útil de tus impresoras.",
        img: SoporteImpImg,
    },
    {
        tipe: "banner",
        title: "Actualizacion / Repotenciación de Computadores",
        subtitle: "Aumenta el Rendimiento: Repotenciación de Computadores",
        text: "¿Sientes que tu computadora está quedándose atrás en términos de velocidad y rendimiento? En R&C Computadores Cúcuta, ofrecemos servicios de repotenciación para darle nueva vida a tu equipo. Desde la actualización de componentes hasta la optimización del sistema, nos aseguramos de que tu computadora funcione más rápido y eficientemente. Descubre cómo podemos impulsar el rendimiento de tu computadora y mejorar tu experiencia informática diaria.",
        img: SoporteUpImg,
    }
];
