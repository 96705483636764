import styled from "styled-components";

const StyledImg = styled.img`
    width: 100px;
    margin-right: 10px;
`

const Logo = () =>{
    return <StyledImg src="/img/logo.webp" alt="logo ryc" />
}

export default Logo;