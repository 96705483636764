import styled from "styled-components";

const StyledP = styled.p`
    color: #00bbf9;
    margin-bottom: 1rem;
`

const Text = ({text}) => {
    return <StyledP>{text}</StyledP>
};

export default Text;