import styled from "styled-components";

const StyledTitle = styled.h1`
    font-size: 24px;
    color: #00bbf9;
    font-weight: bold;
`

const Title = ({text}) =>{
    return <StyledTitle>{text}</StyledTitle>
}

export default Title;