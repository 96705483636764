import styled from "styled-components";
import Linkage from "./Linkage";
import Logo from "./Logo";

const StyledFooter = styled.div`
    margin-top: auto;
    padding: 15px 0;
    background: #00bbf9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 1rem;

    @media screen and (max-width: 650px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`

const Lista = styled.ul`
    li{ 
        margin-bottom: .3rem; 
        display: flex;
    }

    li ion-icon{
        font-size: 1.3rem;
        margin-right: .5rem;
        transition: all 0.5s;
    }

    @media screen and (max-width: 650px) {
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;

        li{
            display: flex;
            justify-content: center;
        }
    }
`

const ListaRedes = styled(Lista)`
    display: flex;
`  

const ListaEnlaces = styled(Lista)`
    @media screen and (max-width: 650px) {
        display: flex;
    }
`

const ContainerEnlaces = styled.div`
    @media screen and (max-width: 650px) {
        text-align: center;
    }
`

const Titulo = styled.h2`
    font-size: 1.2rem;
    text-transform: uppercase;
    margin-bottom: .5rem;
    font-weight: 700;
`

const Box = styled.div`
    display: flex;
    flex-direction: column;

    p{
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

const Footer = () => {
    return <StyledFooter>
        <Container>
            <Lista>
                <li><ion-icon name="call"></ion-icon><Linkage nombre="310 276 0004" to="tel:+573102760004"/></li>
                <li><ion-icon name="mail"></ion-icon><Linkage nombre="ryccucuta@hotmail.com" to="mailto:ryccucuta@hotmail.com"/></li>
                <li><ion-icon name="business"></ion-icon><Linkage nombre='C.C. Bulevar - Local 225F Cúcuta, Colombia' to="https://goo.gl/maps/7gwTdFPnpvngMwmm6" target={"_blank"}/></li>
            </Lista>
            <div>
                <Titulo>Redes Sociales</Titulo>
                <ListaRedes>
                    <li><Linkage nombre = <ion-icon size="large" name="logo-facebook"></ion-icon> to ="https://www.facebook.com/RyCCucuta" target={"_blank"}/></li>
                    <li><Linkage nombre = <ion-icon size="large" name="logo-instagram"></ion-icon> to ="https://www.instagram.com/ryccucuta/" target={"_blank"}/></li>
                </ListaRedes>
            </div>
            <ContainerEnlaces>
                <Titulo>Enlaces</Titulo>
                <ListaEnlaces>
                    <li><Linkage nombre ="Inicio" to ="/"/></li>
                    <li><Linkage nombre ="Servicios" to ="/servicios"/></li>
                    <li><Linkage nombre ="Contacto" to ="/contacto"/></li>
                </ListaEnlaces>
            </ContainerEnlaces>
        </Container>
        <Box>
            <p>©2023 R&C Computadores Cúcuta</p>
            <p>Designed by<Linkage nombre =": Andrés Montes" to="https://andresmontes.dev/" target={"_blank"}/></p>
        </Box>
    </StyledFooter>
};

export default Footer;