import styled from "styled-components";

const Container = styled.div`
    width: 500px;
    height: 300px; 
    position: fixed;
    z-index: 2;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    right: calc(50% - 250px);
    top: calc(50% - 150px);
    border: 2px solid #25D366; 
    

    @media screen  and (max-width: 800px){
        width: 340px;
        height: 200px; 
        right: calc(50% - 170px);
        top: calc(50% - 100px); 
    }
`

const Message = styled.p`
    text-align: center;
    color: #25D366;
`

const Boton = styled.button`
    display: flex;
    z-index: 1;
    position: absolute;
    bottom: 5%;
    border-radius: 50%;
    padding: 0;
    border: none;
    background: none;

    ion-icon{
        color: #25D366;
        border-radius: 50%;
        font-size: 2rem;
        transition: all .3s;

        :hover{
            transform: scale(1.1);
        }
    }
`

const MessageWindow = ({funcion, text}) => {
    return <Container>
        <Message>{text}</Message>
        <Boton onClick={()=>funcion()}><ion-icon name="checkmark-circle"></ion-icon></Boton>
    </Container>
};

export default MessageWindow;