import styled from "styled-components";
import Container from "../../../components/Container"
import Text from "../../../components/Text";
import Title from "../../../components/Title";
import { techSupportData } from "../../js/data";
import { Image } from "react-bootstrap";

const Description = styled.h3`
    color: #00bbf9;
    font-size: 1.2rem;
    font-weight: 700;
    border-bottom: 2px solid #00bbf9;
    margin-bottom: 1rem;
`;

const Section = styled.div`
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 10px 15px;
    border-radius: 18px;
    border: 1px solid #00bbf9;

    @media screen and (max-width: 767px) {
        h1{ 
            font-size: 20px;
        }
        h3{
            font-size: 17px;
        }
    }
`;

const TechSupport = () => {
    return <Container>
        <>  
            {techSupportData.map((techData, i) => {
                const {tipe, title, subtitle, text, img} = techData;
                return <Section key={i}>
                    <Title text={title}/>
                    <Description>{subtitle}</Description>
                    <Image src={img} alt="image" style={{alignSelf: "center"}} fluid/>
                    <Text text={text}/>
                </Section>
            })}
        </>
    </Container>
};

export default TechSupport;