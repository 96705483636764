import styled from "styled-components";
import Menu from "./Menu";

const VideoContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1rem;
`

const VideoCard = styled.div`
    max-width: 400px;
    margin: 1rem auto;
`

const SubTitle = styled.h3`
    color: #00bbf9;
    font-weight: 600;
    margin-bottom: .2rem;
`

const VideoFrame = styled.iframe`
    width: 100%;
    height: 220px;
`

const ContainerTutorials = ({tutorial}) => {

    let menuData = [];
    const menuRepuestos = ["TODO","HP", "EPSON"];
    const menuReparaciones = ["TODO", "COMPUTADORES", "IMPRESORAS"];

    if(tutorial === "repuesto"){
        menuData = menuRepuestos;
    }else{
        menuData = menuReparaciones;
    }

    return <>
        <Menu options={menuData}/>
        <VideoContainer>
            <VideoCard>
                <SubTitle>Como instalar impresora HP</SubTitle>
                <VideoFrame src="https://www.youtube.com/embed/Ori2VcOs_Jo" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></VideoFrame>
            </VideoCard>
            <VideoCard>
                <SubTitle>Como instalar impresora HP</SubTitle>
                <VideoFrame src="https://www.youtube.com/embed/Ori2VcOs_Jo" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></VideoFrame>
            </VideoCard>
            <VideoCard>
                <SubTitle>Como instalar impresora HP</SubTitle>
                <VideoFrame src="https://www.youtube.com/embed/Ori2VcOs_Jo" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></VideoFrame>
            </VideoCard>
            <VideoCard>
                <SubTitle>Como instalar impresora HP</SubTitle>
                <VideoFrame src="https://www.youtube.com/embed/Ori2VcOs_Jo" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></VideoFrame>
            </VideoCard>
            <VideoCard>
                <SubTitle>Como instalar impresora HP</SubTitle>
                <VideoFrame src="https://www.youtube.com/embed/Ori2VcOs_Jo" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></VideoFrame>
            </VideoCard>
        </VideoContainer>
    </>
};

export default ContainerTutorials;