import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { bannerInfo } from "../assets/js/data";
import styled from 'styled-components';

const Container = styled.div`
    margin: 1rem .2rem;
    border-radius: 8px;

    img{
        border: 2px solid #00bbf9;
        border-radius: 8px;
    }

    .carousel-caption{
        background-color: rgba(0, 187, 249, .5);
        border-radius: 8px;
    } 
`

const Slideshow = () => {
  return (
    <Container>
        <Carousel fade>
            {bannerInfo.map((image, i) => {
                const {title, texto, img} = image;

                return <Carousel.Item key={i}>
                    <img
                    className="d-block w-100"
                    src={img}
                    alt="First slide"
                    />
                    <Carousel.Caption>
                    <h3>{title}</h3>
                    <p>{texto}</p>
                    </Carousel.Caption>
                </Carousel.Item>
            })}
        </Carousel>
    </Container>
    
  );
}

export default Slideshow;