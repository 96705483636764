import styled from "styled-components";

const StyledContainer = styled.div`
    width: 100%;
    max-width: 1300px;
    padding: 1rem;
    margin-bottom: 1rem;
    margin: 0 auto;
`

const Container = ({children}) => {
    return <StyledContainer>
        {children}
    </StyledContainer>
};

export default Container;