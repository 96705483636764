import styled from "styled-components";
import Container from "../../components/Container"
import { Link } from "react-router-dom";



const ErrorText = styled.h2`
    color: #00bbf9;
    font-size: 1.3rem;
    text-align: center;
    ion-icon{
        color: #00bbf9;
        font-size: 8rem;
    }
`;

const ErrorTitle = styled(ErrorText)`
    
    font-weight: bold;
    font-size: 3rem;
`;

const Button = styled(Link)`
    margin: 1rem auto;
    width: 50%;
    display: block;
    text-align: center;
    text-decoration: none;
    padding: 1rem;
    border-radius: 18px;
    border: 2px solid #00bbf9;
    color: #00bbf9;
    font-weight: bold;

    :hover{
        background-color: #d0ecf5;
        color: #00bbf9;
    }
`;

const NotFound = () => {
    return <Container>
        <>  
            <ErrorTitle><ion-icon name="sad-outline"></ion-icon></ErrorTitle>
            <ErrorTitle>ERROR 404</ErrorTitle>
            <ErrorTitle>PAGINA NO ENCONTRADA</ErrorTitle>
            <ErrorText>LA PAGINA A LA QUE ESTA INTENTANDO ACCEDER NO EXISTE</ErrorText>
            <Button to={"/"}>Regresar al Inicio</Button>
        </>
    </Container>
};

export default NotFound;