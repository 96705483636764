import { Container } from "react-bootstrap";
import { cardsInfo } from "../js/data";
import Menu from "../../components/Menu";
import { Route, Routes } from "react-router-dom";
import TechSupport from "./services/TechSupport";
import SubTitle from "../../components/SubTitle";


const Services = () => {
    const menuData = [];

    cardsInfo.forEach((item)=> {
        menuData.push([item.title, item.route])
    });

    const menuDataTemp = [["SOPORTE TECNICO", "soporte"]];

    return <Container>
        <>
            <SubTitle text={"Servicios"}/>
            <Menu options={menuDataTemp}/>
            <Routes>
                <Route path="soporte" element={<TechSupport/>}/>
                <Route path="software" element={<TechSupport/>}/>
                <Route path="web" element={<TechSupport/>}/>
                <Route path="accesorios" element={<TechSupport/>}/>
                <Route path="repuestos" element={<TechSupport/>}/>
                <Route path="suministros" element={<TechSupport/>}/>
            </Routes>
        </>
    </Container>
};

export default Services;