import Header from './components/Header.jsx';
import Main from './assets/pages/Main.jsx';
import Footer from './components/Footer.jsx';
import GlobalStyle from './GlobalStyle.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Services from './assets/pages/Services.jsx';
import Tutorials from './assets/pages/Tutorials.jsx';
import Contact from './assets/pages/Contact.jsx';
import WhatsAppBtn from './components/WhatsAppBtn.jsx';
import { Analytics } from '@vercel/analytics/react';
import NotFound from './assets/pages/NotFound.jsx';
import { cardsInfo } from './assets/js/data.js';

function App() {

  return (
    <Router>
      <GlobalStyle/>
      <Header/>
      <WhatsAppBtn/>
      <Routes>
        <Route
          path='/'
          element={<Main/>}
        />
        <Route path='/servicios' element={<Services/>}>
          {cardsInfo.map((card, i) => {
            return <Route key={i} path={card.route}/>
          })}
        </Route>
        <Route
          path='/tutoriales'
          element={<Tutorials/>}
        />
        <Route
          path='/contacto'
          element={<Contact/>}
        />
        <Route
          path='*'
          element={<NotFound/>}
        />
      </Routes>
      <Footer/>
      <Analytics/>
    </Router>
  );
}

export default App;
