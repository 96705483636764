import styled from "styled-components";
import { Link } from "react-router-dom";
import Text from "./Text";
import { useState } from "react";

const Contenedor = styled.div`
    position: relative;
    position: fixed;
    right: 1.5rem;
    bottom: 1.5rem;
    z-index: 1;

    a ion-icon{
        color: #25D366;
        background: #ffffff;
        font-size: 3rem;
        border-radius: 50%;
        padding: .5rem;
        transition: all .3s;

        :hover{
            transform: rotate(360deg);
            color: #ffffff;
            background: #25D366;
        }
    }
`

const MessageContainer = styled.div`
    transition: all .3s;
    animation-name: WADialog;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;

    p{  
        width: 220px;
        position: absolute;
        left: -14rem;
        color: #25D366;
        background: #ffffff;
        border: #25D366 2px solid;
        border-radius: 8px;
        padding: .5rem;
        
    }

    @keyframes WADialog{
        0%{
            transform: translateY(5px);
        }
        50%{
            transform: translateY(-5px);
        }
        100%{
            transform: translateY(5px);
        }
    }
`

const Boton = styled.button`
    display: flex;
    z-index: 1;
    position: absolute;
    right: 4rem;
    top: -.5rem;
    border-radius: 50%;
    padding: 0;
    border: none;
    background: none;

    ion-icon{
        color: red;
        border-radius: 50%;
        font-size: 1.5rem;
        background: #fff;
    }
`

const WhatsAppBtn = () => {
    const [message, setMessage] = useState(true);

    const hiddenMessage = () => {
        setMessage(false);
    };

    return <Contenedor>
        {message && <MessageContainer>
                <Boton onClick={()=>hiddenMessage()}><ion-icon name="close-circle"></ion-icon></Boton>
                <Text text={"¿Alguna duda? Comunicate con nosotros..."}/>
            </MessageContainer>}
        <Link target="_blank" rel="noopener noreferrer" to={"https://api.whatsapp.com/send?phone=+573102760004&text=%C2%A1Hola!%20Quiero%20informaci%C3%B3n..."}>
            <ion-icon name="logo-whatsapp"></ion-icon>
        </Link>
    </Contenedor>
};

export default WhatsAppBtn;