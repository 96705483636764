import { Container } from "react-bootstrap"
import SubTitle from "../../components/SubTitle";
import Text from "../../components/Text";
import styled from "styled-components";
import { useState } from "react";
import ContainerTutorials from "../../components/ContainerTutorials";
import { Link } from "react-router-dom";

const StyledH3 = styled.h3`
    color: #00bbf9;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin: 2rem;
    cursor: pointer;
    transition: all .3s;

    :hover, ion-icon:hover{
        color: #00f5d4;
    }
`

const StyledLink = styled(Link)`
    color: #00bbf9;
    text-decoration: none;
    transition: all .3s;
    font-size: 1.2rem;
    margin: 1rem;

    :hover, ion-icon:hover{
        color: #00f5d4;
    }
`

const Tutorials = () => {
    const [firstTutorials, setfirstTutorials] = useState(true);
    const [secondTutorials, setsecondTutorials] = useState(false);


    return <Container>
        <>
            <SubTitle text={"Tutoriales"}/>
            <Text text={"En esta sección puedes encontrar contenido que te ayudara a realizar cambios de piezas adquiridas en nuestra tienda, como también a solucionar problemas comunes que puedas presentar tanto en computadores como en equipos de impresión."}/>
            <StyledH3 onClick={() =>setfirstTutorials(!firstTutorials)}>¿Adquiriste un repuesto y no sabes como instalarlo?</StyledH3>
            {firstTutorials && <ContainerTutorials tutorial="repuesto"/>}
            <StyledH3 onClick={() =>setsecondTutorials(!secondTutorials)}>¿Tu computador o impresora presentan fallas?</StyledH3>
            {secondTutorials && <ContainerTutorials tutorial="reparacion"/>}
            <Text text={"Si no encontraste una solución a tu problema, puedes ponerte en contácto con nosotros para poder asesorarte!"}/>
        </>
    </Container>
};

export default Tutorials;