import styled from "styled-components";

const Container = styled.div`
    width: 300px;
    height: 225px;
    padding: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    color: #00bbf9;
    border: 2px solid rgba(247, 247, 247, 0);
    cursor: pointer;
    transition: 100ms ease-in-out;

    ion-icon{
        font-size: 2.5rem;
        color: #00bbf9;
        margin-bottom: 1rem;
    }

    :hover{
        transition: 100ms ease-in-out;
        border: 2px solid #00bbf9;
        border-radius: 18px;
        background-color: #d0ecf5;
    }
`

const Titulo = styled.h2`
    color: #00bbf9;
    font-weight: bold;
    font-size: 1.3rem;
    margin-bottom: 1rem;
`

const Descripcion = styled.p`
    color: #00bbf9;
`

const Card = ({data}) => {
    const {iconName, title, description} = data;

    return <Container>
        <ion-icon name={iconName}></ion-icon>
        <Titulo>{title}</Titulo>
        <Descripcion>{description}</Descripcion>
    </Container>
};

export default Card;