import styled from "styled-components";

const StyledH2 = styled.h2`
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: #00bbf9;
    border-bottom: 3px solid #00bbf9;
    margin-bottom: 1rem;
`

const SubTitle = ({text}) => {
    return <StyledH2>{text}</StyledH2>
};

export default SubTitle;