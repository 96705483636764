import styled from "styled-components";
import Tarjeta from "../../components/Card";
import { cardsInfo } from "../js/data";
import MShopsImg from "../images/logo_mercadoshops.svg";
import Linkage from "../../components/Linkage";
import Slideshow from "../../components/Slideshow";
import Container from "../../components/Container";
import Title from "../../components/Title";
import Text from "../../components/Text";

const CardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
`

const MShopsContainer = styled.div`
    text-align: center;
    margin-bottom: 2rem;
`

const SubTitle = styled.h2`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.3rem;
    color: #00bbf9;

    ion-icon{
        color: #00bbf9;
        font-size: 2rem;
        margin-right: 1rem;
    }
`

const Img = styled.img`
    width: 250px;
    margin: auto;
    margin-bottom: 1rem;
`

const Description = styled.p`
    color: #00bbf9;
    margin-bottom: .5rem;
`

const Main = () => {
    return <Container>
        <>
            <Title text={"R&C Computadores Cúcuta"}/>
            <Text text={"En RYC Computadores Cúcuta, nos enorgullece ofrecer soluciones tecnológicas avanzadas que impulsarán tu negocio hacia el éxito en la era digital."}/>
            
            <Slideshow/>
            <SubTitle><ion-icon name="rocket-outline"></ion-icon> DESCUBRE NUESTROS SERVICIOS</SubTitle>
            <Text text={"Desde el desarrollo web de vanguardia hasta servicios de mantenimiento y reparación, en RYC Computadores Cúcuta estamos comprometidos a proporcionar soluciones integrales que se adaptan a tus necesidades únicas."}/>
            <CardsContainer>
                {cardsInfo.map((card, i) =>{
                    return <Tarjeta key={i} data={card}/>
                })}
            </CardsContainer>
            <MShopsContainer>
                <SubTitle>Visita nuestra tienda en MercadoShops</SubTitle>
                <Linkage to={"https://ryccucuta.mercadoshops.com.co/"} target={"_blank"} nombre={<Img src={MShopsImg}/>}/>
                <Description>○ Compras seguras.</Description>
                <Description>○ Envíos rapidos y seguros.</Description>
                <Description>○ Paga con casi cualquier medio de pago.</Description>
                <Description>○ Paga a cuotas y sin intereses.</Description>
            </MShopsContainer>
        </>
    </Container>
};

export default Main;