import styled from "styled-components";
import { Container } from "react-bootstrap";
import SubTitle from "../../components/SubTitle";
import Text from "../../components/Text";
import { useState } from "react";
import MessageWindow from "../../components/MessageWindow";

const Formulario = styled.form`
    max-width: 500px;
    margin: 2rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Label = styled.label`
    width: 100%;
    position: relative;
    left: 0;
    margin-bottom: 1rem;
`

const Span = styled.span`
    position: absolute;
    left: 15px;
    top: 17px;
    color: #00bbf9;
    transition: all .3s;
`

const Input = styled.input`
    border: none;
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    transition: all .3s;
    color: #00bbf9;
    outline: none;

    :not(:placeholder-shown){
        + span{
            top: -9px;
            font-size: .9rem;
        }
    }

    &:focus{
        + span{
            top: -9px;
            font-size: .9rem;
        }
    }
`

const TextArea = styled.textarea`
    border: none;
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    transition: all .3s;
    color: #00bbf9;
    outline: none;

    :not(:placeholder-shown){
        + span{
            top: -9px;
            font-size: .9rem;
        }
    }

    &:focus{
        + span{
            top: -9px;
            font-size: .9rem;
        }
    }
`

const Button = styled.button`
    border: 1px solid #ffffff;
    background: #00bbf9;
    width: 300px;
    padding: 15px;
    border-radius: 8px;
    transition: all .3s;

    :hover{
        border: 1px solid #00bbf9;
        background: rgba(0, 187, 249, .3);
        color: #00bbf9;
        font-weight: 600;
    }
`



const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [subjet, setSubjet] = useState("");
    const [message, setMessage] = useState("");

    const [messageWindow, setMessageWindow] = useState(false);

    const handleChange = (funcion, e) => {
        funcion(e.target.value);
    };

    const sendMessage = (e) => {
        e.preventDefault();

        const cuerpoCorreo = 
        `Nombre: ${name}%0D%0ATeléfono: ${phone}%0D%0ACorreo: ${email}%0D%0AMensaje: ${message}%0D%0A`;
        const linkCorreo = `mailto:hypnoz@live.com?subject=${subjet}&body=${cuerpoCorreo}`;
        window.location.href = linkCorreo;

        setMessageWindow(true);
    };

    return <Container>
        <>
            {messageWindow && <MessageWindow text="A continuación, se abrirá tu gestor de correos electrónicos predeterminado para terminar de enviar tu mensaje. Te responderemos en la mayor brevedad posible." funcion={()=>setMessageWindow(false)}/>}
            <SubTitle text={"Contacto"}/>
            <Text text={"Escríbenos y nos pondremos en contacto contigo en la mayor brevedad posible."}/>
            <Formulario onSubmit={(e)=>sendMessage(e)}>
                <Label>
                    <Input 
                        value={name} 
                        type="text" 
                        placeholder=" "
                        required
                        onChange={(e)=>handleChange(setName, e)}>
                    </Input>
                    <Span>Nombre:</Span>
                </Label>
                <Label>
                    <Input 
                        value={email} 
                        type="email" 
                        placeholder=" "
                        required
                        onChange={(e)=>handleChange(setEmail, e)}>
                    </Input>
                    <Span>Email:</Span>
                </Label>
                <Label>
                    <Input value={phone} 
                        type="tel" 
                        placeholder=" "
                        required
                        onChange={(e)=>handleChange(setPhone, e)}>
                    </Input>
                    <Span>Telefono:</Span>
                </Label>
                <Label>
                    <Input 
                        value={subjet} 
                        type="text" 
                        placeholder=" "
                        required
                        onChange={(e)=>handleChange(setSubjet, e)}>
                    </Input>
                    <Span>Asunto:</Span>
                </Label>
                <Label>
                    <TextArea 
                        value={message} 
                        rows={5} 
                        placeholder=" "
                        required
                        onChange={(e)=>handleChange(setMessage, e)}>
                    </TextArea>
                    <Span>Mensaje:</Span>
                </Label>
                <Button>Enviar</Button>

            </Formulario>
        </>
    </Container>
};

export default Contact;